let chooseCountryBlock = document.querySelector('.choose-country');
console.log(chooseCountryBlock);
if(chooseCountryBlock) {
    const selectList = chooseCountryBlock.querySelector('#country-list');
    let allItems = null;
    let activeCategories = null;

    let countryCode = sessionStorage.getItem('countryCode') || 'other';


    if (countryCode){
        selectElement(selectList, countryCode);
    }

    // Wait for the window to load before running the code
    window.addEventListener('load', function() {

        // Get all the dealer items
        allItems = document.querySelectorAll('.individual-dealer-item, .individual-smart-item');

        // Update the list based on the selected country
        updateList(allItems, selectList);


        // Add an event listener to the select list
        if(allItems) {
            selectList.addEventListener('change', function selectListChange() {
                // Update the list based on the selected country   
                let otherMarketsFormBlock = document.querySelector('.other-markets-form');
                if(selectList.value == 'other' && otherMarketsFormBlock) {
                    otherMarketsFormBlock.classList.remove('hide');
                } else if(selectList.value != 'other' && otherMarketsFormBlock) {
                    otherMarketsFormBlock.classList.add('hide');
                }
                updateList(allItems, selectList);
            });
        }   
    });

    function selectCountryElement(countryCode) {
        selectElement(selectList, countryCode);
    }

    window.selectCountryElement = selectCountryElement;
}

function selectElement(selectList, valueToSelect) {
    // Find the option with the value to select (i.e. "fi")
    selectList.value = Array.from(selectList).find(el => el.value === valueToSelect)?.value || 'other';
    let otherMarketsFormBlock = document.querySelector('.other-markets-form');
    if(selectList.value == 'other' && otherMarketsFormBlock) {
        otherMarketsFormBlock.classList.remove('hide');
    } else if(selectList.value != 'other' && otherMarketsFormBlock) {
        otherMarketsFormBlock.classList.add('hide');
    }
}

function updateList(allItems, selectList) {
    let activeCategories = document.querySelector('.accordion-wrapper.active .item-container');
    allItems.forEach((item) => {
        if(item.dataset.countrycode === selectList.value) {
            item.classList.remove('hide');
        } else if (selectList.value === 'all') {
            // If the select list value is "all", dont change anything
            return;
        } else {
            item.classList.add('hide');
        }
    })

    if(activeCategories) {
        activeCategories.style.maxHeight = activeCategories.scrollHeight+'px';
    }
    updateAccordionVisibility();
}

function updateAccordionVisibility() {
    // Get all the blocks
    const blocks = document.querySelectorAll('.find-dealer-country, .smart-accordion');
    
    // Loop through each block
    blocks.forEach((block) => {

        // Get all the categories
        const categories = block.querySelectorAll('.accordion-wrapper');
        let visibleCategories = [];
        
        // Loop through each category
        categories.forEach((category) => {
            
            // Get all the items in the category
            const categoryItems = category.querySelectorAll('.individual-dealer-item, .individual-smart-item');
            let visibleItems = 0;
            
            // Loop through each item
            categoryItems.forEach((item) => {
                // If the item is not hidden, increment the visibleItems counter
                if(!item.classList.contains('hide')) {
                    visibleItems++;
                }
            })
            
            // If there are visible items, remove the hide class from the category
            if(visibleItems > 0) {
                category.classList.remove('hide');
                visibleCategories.push(category);
            } else {
                category.classList.add('hide');
            }
            
        })

        if(visibleCategories.length == 0) {
            block.classList.add('hide');
        } else {
            block.classList.remove('hide');
            // Update the count of visible categories
            visibleCategories.forEach((category, index) => {
                let countElement = category.querySelector('.count');
                countElement.innerHTML = "0"+ (index+1);
            });
        }

    })
}